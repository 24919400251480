// Copyright 2021-2025 - Hewlett Packard Enterprise Company

import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import {
  SESSION_KEY,
  CUSTOMER_ID_KEY,
  WORKSPACE_ID_KEY,
  DEV_ACID_KEY,
  TENANT_ACID_KEY,
} from '../../constants/authConst';

const SKIP_STORAGE_UPDATE = true;

export default function StorageEventHandler() {
  const history = useHistory();
  const {
    getCid,
    login,
    tenantAcid,
    devAcid,
    updateDevAcid,
    updateTenantAcid,
  } = useContext(AuthContext);

  useEffect(() => {
    let isMounted = true;

    const onStorageChange = ev => {
      if (ev.key === CUSTOMER_ID_KEY) {
        const newCid = ev.newValue;
        getCid().then(curCid => {
          if (isMounted && newCid && newCid !== curCid) {
            const workspaceId = localStorage.getItem(WORKSPACE_ID_KEY);
            login({ cid: newCid, workspaceId });
          }
        });
      } else if (ev.key === SESSION_KEY && ev.newValue === 'logout') {
        window.removeEventListener('storage', onStorageChange);
        // eslint-disable-next-line no-console
        console.log('Session has terminated. Forwarding to logout');
        window.location = '/logout';
      } else if (ev.key === TENANT_ACID_KEY && tenantAcid !== ev.newValue) {
        updateTenantAcid(ev.newValue, SKIP_STORAGE_UPDATE);
        history.replace('/');
      } else if (ev.key === DEV_ACID_KEY && devAcid !== ev.newValue) {
        updateDevAcid(ev.newValue, SKIP_STORAGE_UPDATE);
        history.replace('/');
      }
    };

    localStorage.removeItem(SESSION_KEY);
    window.addEventListener('storage', onStorageChange);

    return () => {
      isMounted = false;
      window.removeEventListener('storage', onStorageChange);
    };
  }, [
    getCid,
    login,
    tenantAcid,
    devAcid,
    updateTenantAcid,
    updateDevAcid,
    history,
  ]);

  return null;
}
