// Copyright 2023-2025 - Hewlett Packard Enterprise Company
const COM_API_ROOT_URL = window.indigo.REACT_APP_COM_API_ROOT_URL;

export const PAGE_LIMIT = 1000;

export const COMPUTE_OPS_URL = `${COM_API_ROOT_URL}/compute-ops`;
export const DOORWAY_URL = `${COM_API_ROOT_URL}/ui-doorway`;
export const SERVERS_URL = `${DOORWAY_URL}/compute/v2/servers`;
export const COMPUTE_OPS_MGMT_URL = `${COM_API_ROOT_URL}/compute-ops-mgmt`;

export const MAX_DOORWAY_COUNT = 800;
